
import { ComponentPublicInstance, computed, defineComponent, onMounted, reactive, ref } from 'vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import { especiesState, estadoMaderasState, estadoRumasState, canchasState } from '@/store/mantenedores.store';
import { cleanSource, validateForm } from '@/utils';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { canchaState } from '@/store/cancha.store';
import { showToast } from '@/hooks/useToast';
import { useRouter } from 'vue-router';
import { userState } from '@/store/user.store';

export default defineComponent({
  name: 'CreateRuma',
  components: {
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown
  },
  setup() {
    const canchaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const especieRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const estadoMaderaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const estadoRumaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const numeroRumaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    //
    const errors = ref<string[]>([]);
    const creating = ref(false);
    const router = useRouter();
    const ruma = reactive({
      numero: 0,
      cancha: '',
      especie: '',
      estadoMadera: '',
      estadoRuma: '60dfe7c9ba0ccfd21a8e04f3'
    });
    const canchas = computed(() =>
      canchasState.value.map((el) => ({
        id: el._id,
        label: `${el.codigo} ${el.descripcion}`
      }))
    );
    const especies = especiesState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const estadoMadera = estadoMaderasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const estadoRuma = estadoRumasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const user = computed(() => userState.value);
    const cancha = computed(() => canchaState.value);
    const resetRumaFormFn = () => {
      ruma.numero = 0;
      ruma.cancha = '';
      ruma.especie = '';
      ruma.estadoMadera = '';
      ruma.estadoRuma = '60dfe7c9ba0ccfd21a8e04f3';
      canchaRef.value?.cleanValue();
      especieRef.value?.cleanValue();
      estadoMaderaRef.value?.cleanValue();
      estadoRumaRef.value?.cleanValue();
    };
    const createRumaFn = async () => {
      const isValid = validateForm([numeroRumaRef, canchaRef, especieRef, estadoMaderaRef, estadoRumaRef]);
      if (!isValid) return;
      try {
        creating.value = true;
        const body: any = cleanSource(ruma);
        const request = await makeRequest(METHODS.POST, '/ruma', true, body);
        if (request.ok) {
          showToast('SUCCESS', 'Ruma creada exitosamente');
          router.push({ name: 'rumas.list' });
          errors.value = [];
        } else {
          showToast('ERROR', 'Error en proceso de creación');
          request.errors && (errors.value = request.errors.split(','));
        }
      } catch (error) {
        console.log(error);
      } finally {
        creating.value = false;
      }
    };
    
    const canchaDisabled = computed(() => ({ disabled: user.value?.role === 'RECEPTOR' && user.value.cancha !== 'ALL', default: user.value?.cancha }));
    onMounted(() => {
      if (user.value?.role === 'RECEPTOR' && user.value.cancha !== 'ALL') {
        ruma.cancha = user.value.cancha;
      }
    });

    return {
      ruma,
      cancha,
      canchas,
      especies,
      estadoMadera,
      estadoRuma,
      createRumaFn,
      numeroRumaRef,
      canchaRef,
      especieRef,
      estadoMaderaRef,
      estadoRumaRef,
      errors,
      creating,
      resetRumaFormFn,
      canchaDisabled
    };
  }
});
